import React, { useEffect, useState, useRef } from "react";
import "./ordersTab.scss";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import ProductInfo from "../orders/productInfo/productInfo";
import PackingSlip from "./packingSlip/packingSlip";
import Invoice from "./Invoice/Invoice";
import PackiReturnLabelSlip from "./returnLabel/ReturnLabel";
import Button from "../../../components/button/button";
import Box from "@material-ui/core/Box";
import { useHistory, useParams } from "react-router-dom";
import { getMethodWithPlatfrom, getMethod, postMethodWithToken, deleteMethodWithToken, postMethodWithPlatform, getMethodWithPickUp, postMethodWithPickUp, postMethod, postMethodWithPickUpPlatform, postMethodWithPickUpToken, putMethodWithToken, getMethodWithExpreesDeliver } from "../../../utils/services/api";
import { getUserRole, getStoreId } from "../../../utils/common";
import urls from "../../../utils/services/apiUrl.json";
import moment from "moment";
import Modal from "react-modal";
import { useReactToPrint } from "react-to-print";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import DeleteIcon from '@material-ui/icons/Delete';
import PinDropIcon from '@material-ui/icons/PinDrop';
import { toast } from 'react-toastify';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import OrderReceivedIcon from "../../../assets/images/order/orderReceived.png";
import OrderinProcessIcon from "../../../assets/images/order/orderinProcess.png";
import OrderReadyIcon from "../../../assets/images/order/orderReady.png";
import OrderShippedIcon from "../../../assets/images/order/orderShipped.png";
import SpinnerLoader from "../../../components/loader/spinnerLoader";
import { Checkbox, InputAdornment, TextField } from "@material-ui/core";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import OnHoldModal from "../../../components/OnHoldModal/onHoldModal";
import OtpInput from 'react-otp-input';
import { ValidatorForm } from "react-material-ui-form-validator";
import pickUpModalImage from "../../../assets/images/pickupmodal.png"
import DefaultImage from "../../../assets/images/ShopezDefult.svg"


const checkCancleProcess = data => {
    const emptyKeys = [
        'productId',
        'message',
    ];

    const emptyKeysErrors = [
        'product',
        'message',
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!data[emptyKeys[i]]) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }

        if (data[i] === 'productId' && data[emptyKeys[i]].length === 0) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }
    }

    return '';
};

const checkReturnProcess = data => {
    const emptyKeys = [
        'productId',
        'message',
    ];

    const emptyKeysErrors = [
        'product',
        'message',
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!data[emptyKeys[i]]) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }

        if (data[i] === 'productId' && data[emptyKeys[i]].length === 0) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }
    }

    return '';
};

const checkRefundProcess = data => {
    const emptyKeys = [
        'amount',
    ];

    const emptyKeysErrors = [
        'amount',
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!data[emptyKeys[i]]) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }
    }

    return '';
};

function OrdersTab(props) {
    const orderStatusColor = {
        OrderReceived: "#FA7F04",
        OrderinProcess: "#FFC000",
        OrderReady: "#92D050",
        OrderShipped: "#00B050",
        OrderDelivered: "#1D3762",
        OrderCancelled: "#FF0000",
        OrderReturned: "#C00000",
        OrderPacked: "#1d475c"
    };

    const OrderIdParam = useParams();
    // console.log(OrderIdParam)

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const toSentenceCase = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const componentRefSlip = useRef();
    const componentRefInvoice = useRef();
    const componentRefReturn = useRef();
    const componentRefCancle = useRef();
    const componentRefProductList = useRef();
    const labelClickSlip = useRef();
    const history = useHistory();
    var panelOpen = window.$panelActive;
    const [currTab, setCurrTab] = React.useState(0);
    const [loading, setLoading] = useState(false);
    const [customerDetails, setCustomerDetails] = useState({});
    const [orderHistoryList, setOrderHistoryList] = useState([]);
    const [orderStatus, setOrderStatus] = useState("");
    const [orderOldStatus, setOrderOldStatus] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [labelSlipLink, setLabelSlipLink] = useState("");
    const [orderProductList, setOrderProductList] = useState([]);
    const [orderNotesList, setOrderNotesList] = useState([]);
    const [orderNoteMsg, setOrderNoteMsg] = useState("");
    const [isNotesOpen, setIsNotesOpen] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Start Processing');
    const [isCancleListModalFlag, setIsCancleListModalFlag] = useState(false);
    const [isRefundModalFlag, setIsRefundModalFlag] = useState(false);
    const [refundAmountShowOnlyItem, setRefundAmountShowOnlyItem] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [productInfoData, setProductInfoData] = useState([]);
    const [cancleProductInfoData, setCancleProductInfoData] = useState([]);
    const [returnProductInfoData, setReturnProductInfoData] = useState([]);
    const [orderProductInfoData, setOrderProductInfoData] = useState([]);
    const [orderCancellationFlag, setOrderCancellationFlag] = useState("");
    const [orderReturnedFlag, setOrderReturnedFlag] = useState("");
    const [cancleProductIds, setCancleProductIds] = useState([]);
    const [returnProductIds, setReturnProductIds] = useState([]);
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [processTypeModal, setProcessTypeModal] = useState('cancel');
    const [refundTypeModal, setRefundTypeModal] = useState('cancel');
    const [refundTypeData, setRefundTypeData] = useState([]);
    const [refundStatus, setRefundStatus] = useState("");
    const [refundAmount, setRefundAmount] = useState("");
    const [checkedValue, setCheckedValue] = useState(false);
    const [refundPrice, setRefundPrice] = useState("")
    const [visible, setVisible] = useState(true)
    const [modalMe, setModalMe] = useState(false)
    const [pickUpModal, setPickUpModal] = useState(false)
    const [courierMethod, setCourierMethod] = useState(false)
    const [otpMethod, setOptMethod] = useState(false)
    const [imageMethod, setImageMethod] = useState(false)
    const [signatureMethod, setSignatureMethod] = useState(false)
    const [deliverConfirmationModal, setDeliverConfirmationModal] = useState(false)
    const [otpValue, setOtpValue] = useState("")
    const [driverOtpValue, setDriverOtpValue] = useState("")
    const [completeModal, setCompleteModal] = useState(false)
    const [expreeWithout, setExpressWithout] = useState(false)
    const [returnRefundId, setReturnRefundId] = useState([])
    const [cancelRefundId, setCancleRefundId] = useState([])
    const [driverList, setDriverList] = useState([])
    const [driverResponse, setDriverResponse] = useState("")
    const [driverAllDetail, setDriverAllDetail] = useState({})
    const [ratingValue, setRatingValue] = useState(0)
    const [assignZapper, setAssignZapper] = useState(false)
    const [zapperIdValue, setZapperIdValue] = useState("")
    const [packageId, setPackageId] = useState("")

    const [cancleStateValue, setCancleStateValue] = useState({
        storeInfo_id: getUserRole() === "super-admin" ? OrderIdParam.store_id : getStoreId(),
        order_id: getUserRole() === "super-admin" ? OrderIdParam.order_id : OrderIdParam.id,
        productId: "",
        message: "",
        isCancelled: true,
    });

    const [returnStateValue, setReturnStateValue] = useState({
        storeInfo_id: getUserRole() === "super-admin" ? OrderIdParam.store_id : getStoreId(),
        order_id: getUserRole() === "super-admin" ? OrderIdParam.order_id : OrderIdParam.id,
        productId: "",
        message: "",
        isReturned: true,
    });

    const [refundStateValue, setRefundStateValue] = useState({
        orderId: getUserRole() === "super-admin" ? parseInt(OrderIdParam.order_id) : parseInt(OrderIdParam.id),
        amount: "",
    });

    function handleTabChange(event, newValue) {
        setCurrTab(newValue);
    }

    function handleChangeIndex(index) {
        setCurrTab(index);
    }

    const getCustomerDetails = async (url, type) => {
        setLoading(true);
        await getMethodWithPlatfrom(url, type).then((response) => {
            if (response.status === 200) {
                setCustomerDetails(response?.data);
                setLoading(false);
                setOrderProductList(response?.data?.productInfo)
                setPackageId(response?.data?.package_id)
            }
        });
        setLoading(false);
    };

    const orderHistoryProcess = async () => {
        if (getUserRole() === "super-admin") {
            await getMethodWithPlatfrom(urls.orderStatusHistory + '?order_id=' + OrderIdParam.order_id + '&store_id=' + OrderIdParam.store_id, "super-admin").then((response) => {
                if (response.data.latestStatus === 'Order Shipped') {
                    setButtonLabel('Return Order');
                } else if (response.data.latestStatus === 'Order Ready' || (response.data.latestStatus === 'Order Cancelled' && response.data.oldStatus === 'Order Ready')) {
                    setButtonLabel('Complete Order');
                } else if (response.data.latestStatus === 'Order in Process' || (response.data.latestStatus === 'Order Cancelled' && response.data.oldStatus === 'Order in Process')) {
                    setButtonLabel('Finish Processing');
                } else {
                    setButtonLabel('Start Processing');
                }

                setOrderStatus(response?.data?.latestStatus)
                setOrderOldStatus(response?.data?.oldStatus)
                setPaymentStatus(response?.data?.paymentStatus)
                setOrderHistoryList(response?.data?.history)
            });
        } else {

            if (OrderIdParam.order === "pickUp") {
                await getMethodWithPickUp(urls.orderStatusHistory + '?order_id=' + OrderIdParam.id).then((response) => {
                    if (response?.data?.latestStatus === "Order Picked Up") {
                        setButtonLabel('Return Order');
                    } else if ((response?.data?.latestStatus === 'Order Ready' || response?.data?.latestStatus === 'Order Shipped') || (response?.data?.latestStatus === 'Order Cancelled' && response.data.oldStatus === 'Order Ready')) {
                        setButtonLabel('Complete Order');
                    } else if (response?.data?.latestStatus === 'Order in Process' || (response?.data?.latestStatus === 'Order Cancelled' && response?.data?.oldStatus === 'Order in Process')) {
                        setButtonLabel('Finish Processing');
                    }
                    else {
                        setButtonLabel('Start Processing');
                    }

                    setOrderStatus(response?.data?.latestStatus)
                    setOrderOldStatus(response?.data?.oldStatus)
                    setPaymentStatus(response?.data?.paymentStatus)
                    setOrderHistoryList(response?.data?.history)
                });
            } else {
                await getMethod(urls.orderStatusHistory + '?order_id=' + OrderIdParam.id).then((response) => {
                    if (response.data.latestStatus === 'Order Shipped') {
                        setButtonLabel('Return Order');
                    } else if (response?.data?.latestStatus === 'Order Ready' || (response?.data?.latestStatus === 'Order Cancelled' && response?.data?.oldStatus === 'Order Ready')) {
                        setButtonLabel('Complete Order');
                    } else if (response?.data?.latestStatus === 'Order in Process' || (response?.data?.latestStatus === 'Order Cancelled' && response?.data?.oldStatus === 'Order in Process')) {
                        setButtonLabel('Finish Processing');
                    } else if (response?.data?.latestStatus === "Order Delivered") {
                        setButtonLabel("")
                    }
                    else {
                        setButtonLabel('Start Processing');
                    }

                    setOrderStatus(response?.data?.latestStatus)
                    setOrderOldStatus(response?.data?.oldStatus)
                    setPaymentStatus(response?.data?.paymentStatus)
                    setOrderHistoryList(response?.data?.history)
                });
            }


        }
    }

    const getOrderDetails = async (url, type) => {
        await getMethodWithPlatfrom(url, type).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setProductInfoData(response?.data?.results?.data);
                setCancleProductInfoData(response?.data?.results?.cancellation);
                setReturnProductInfoData(response?.data?.results?.returned);
                setOrderProductInfoData(response?.data);
                setOrderCancellationFlag(response?.data?.results?.cancellationflag);
                setOrderReturnedFlag(response?.data?.results?.returnedflag);
                setRefundAmount(response?.data?.results?.RefundAmount)
                setRefundStatus(response?.data?.results?.refundStatus)
                response?.data?.results?.returned.forEach((item, index) => {
                    if (item.isRefunded !== true) {
                        returnRefundId.push(item.product_id)
                    }
                })
                response?.data?.results?.cancellation.forEach((item, index) => {
                    if (item.isRefunded !== true) {
                        cancelRefundId.push(item.product_id)
                    }
                })

            }
        });
    };
    const getOrderNotes = async () => {
        if (getUserRole() === "super-admin") {
            getMethod(urls.getOrderNotes + '?order_id=' + OrderIdParam.order_id).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setOrderNotesList(response?.data?.response)
                }
            });
        } else {

            await (OrderIdParam.order === "pickUp" ? getMethodWithPickUp : getMethod)(urls.getOrderNotes + '?order_id=' + OrderIdParam.id).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setOrderNotesList(response?.data?.response)
                }
            });
        }
    }

    const getRefundTypeData = async () => {
        if (getUserRole() === "super-admin") {
            await getMethod(urls.getRefundProcess + '?order_id=' + OrderIdParam.order_id).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setRefundTypeData(response?.data)
                }
            });
        } else {
            await (OrderIdParam.order === "pickUp" ? getMethodWithPickUp : getMethod)(urls.getRefundProcess + '?order_id=' + OrderIdParam.id).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setRefundTypeData(response?.data)

                    response.data.data.forEach((item, index) => {
                        if (response.data.orderStatus === 'Complete' && item.label === 'complete refund') {
                            setRefundStateValue((prevState) => ({
                                ...prevState,
                                ['amount']: +((item.price) * 100)?.toFixed(2),
                            }));
                        }
                        else {
                            setRefundStateValue((prevState) => ({
                                ...prevState,
                                ['amount']: +((item?.price) * 100)?.toFixed(2),
                            }));
                            setRefundPrice(item?.price?.toFixed(2))
                        }
                    })
                }
            });
        }

    }

    useEffect(() => {
        if (getUserRole() === "super-admin") {
            getCustomerDetails(urls.ordersAdmin + "?order_id_details=" + OrderIdParam.order_id, "super-admin");
            getOrderDetails(urls.ordersAdmin + "?stores_id=" + OrderIdParam.store_id + "&order_id=" + OrderIdParam.order_id, "super-admin");
        } else {
            getCustomerDetails(urls.ordersMerchant + "?order_id_details=" + OrderIdParam.id, "merchant");
            getOrderNotes();
            getOrderDetails(urls.ordersMerchant + "?order_id=" + OrderIdParam.id + (OrderIdParam.order === "deliver" ? "&for_delivery=1" : "&for_pick_up=1"), "merchant");
            getSteps()
        }

        orderHistoryProcess();
        if (OrderIdParam?.express === "expressDelivery") {
            getDriverDetail()
        }

    }, []);

    useEffect(() => {
        if (orderStatus === "Order Cancelled" || orderStatus === "Order Returned") {
            getRefundTypeData()
        }
    }, [orderStatus])

    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                setModalMe(response?.data?.on_hold_check_flag)
                setZapperIdValue(response?.data?.prefred_driver?.id)
            }
        });
    };

    const handleProductList = useReactToPrint({
        content: () => componentRefProductList.current,
    });

    const handlePrintSlip = useReactToPrint({
        content: () => componentRefSlip.current,
        onBeforePrint: () => {
            setConfirmationModal(false)
        },
    });

    const handlePrintInvoice = useReactToPrint({
        content: () => componentRefInvoice.current,
    });

    const handlePrintReturn = useReactToPrint({
        content: () => componentRefReturn.current,
    });

    const handlePrintCancle = useReactToPrint({
        content: () => componentRefCancle.current,
    });

    const gotoBack = async () => {
        history.goBack()
    };

    const timeFormat = (date) => {
        let requiredFormat = moment(date).format("lll");
        return requiredFormat;
    };

    const orderStartProcessing = async () => {
        setOrderHistoryList([])

        await (OrderIdParam.order === "pickUp" ? getMethodWithPickUp : getMethod)(urls.orderStatusHistory + '?order_id=' + OrderIdParam.id + '&start_processing=1').then((response) => {
            if (response.status === 200 || response.status === 201) {
                orderHistoryProcess();
                setButtonLabel('Finish Processing');
                setCurrTab(1);
                getSteps()
            } else {
                toast.error(toSentenceCase(response?.data?.response));
                orderHistoryProcess();
            }
        });

    }
    // console.log(OrderIdParam)

    const orderFinishProcessing = async () => {
        setOrderHistoryList([])
        let body = {
            "brodecast": "All"
        }
        if (assignZapper) {
            body["brodecast"] = zapperIdValue
        }

        if (OrderIdParam.order === "pickUp") {
            await getMethodWithPickUp(urls.orderStatusHistory + '?order_id=' + OrderIdParam.id + '&finish_processing=1').then((response) => {
                if (response.status === 200 || response.status === 201) {
                    getSteps()
                    orderHistoryProcess();
                    setButtonLabel('Complete Order');
                    getDriverDetail()
                    setDeliverConfirmationModal(false)
                } else {
                    toast.error(toSentenceCase(response?.data?.message));
                    orderHistoryProcess();
                }
            });
        } else {
            if (OrderIdParam.express === "expressDelivery") {
                let apiUrls;
                if (assignZapper) {
                    apiUrls = urls.orderStatusHistory + '?order_id=' + OrderIdParam.id + '&finish_processing=1' + "&broadcast=" + zapperIdValue;
                } else {
                    apiUrls = urls.orderStatusHistory + '?order_id=' + OrderIdParam.id + '&finish_processing=1' + "&broadcast=All";
                }
                await getMethodWithExpreesDeliver(apiUrls).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        getSteps()
                        orderHistoryProcess();
                        setButtonLabel('Complete Order');
                        getDriverDetail()
                        setDeliverConfirmationModal(false)
                    } else {
                        toast.error(toSentenceCase(response?.data?.message));
                        orderHistoryProcess();
                    }
                });
            } else {
                await getMethod(urls.orderStatusHistory + '?order_id=' + OrderIdParam.id + '&finish_processing=1').then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        getSteps()
                        orderHistoryProcess();
                        setButtonLabel('Complete Order');
                        getDriverDetail()
                        setDeliverConfirmationModal(false)
                    } else {
                        toast.error(toSentenceCase(response?.data?.message));
                        orderHistoryProcess();
                    }
                });
            }
        }
    }
    const handleOtpPickUp = async () => {
        let body = {}
        await postMethodWithToken(urls.postOtpPickUp + '?order_id=' + OrderIdParam.id, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.[0])
            }
        })
    }

    const orderCompleteProcessing = async () => {
        setOrderHistoryList([])
        setOtpValue("");

        if (OrderIdParam.order === "pickUp") {
            await getMethodWithPickUp(urls.orderStatusHistory + '?order_id=' + OrderIdParam.id + '&complete_order=1').then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setPickUpModal(true)
                    getSteps()
                    handleOtpPickUp()
                    setOrderStatus(response?.data?.latestStatus);
                    setOrderOldStatus(response?.data?.oldStatus);
                    setPaymentStatus(response?.data?.paymentStatus);
                    setOrderHistoryList(response?.data?.history);
                    setButtonLabel('Complete Order');

                } else {
                    toast.error(toSentenceCase(response?.data?.response));
                    orderHistoryProcess();
                }
            });
        } else {
            await getMethod(urls.orderStatusHistory + '?order_id=' + OrderIdParam.id + '&complete_order=1').then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setCompleteModal(false)
                    setExpressWithout(false)
                    getSteps()
                    orderHistoryProcess();
                    setButtonLabel('Return Order');
                } else {
                    toast.error(toSentenceCase(response?.data?.response));
                    orderHistoryProcess();
                }
            });
        }
    }

    const getDriverDetail = async () => {
        await getMethod(urls.getDriverDetail + OrderIdParam?.id + "/").then((response) => {
            setDriverResponse(response?.data?.ResponseCode)
            if (response?.data?.ResponseCode === 200 || response?.data?.ResponseCode === 201) {
                setDriverList(response?.data?.ResponseData)
                getDriverAllDetail(response?.data?.ResponseData?.["Order Number"], response?.data?.ResponseData?.package_id)
            }
        })
    }

    const getDriverAllDetail = async (orderNumber, packageId) => {
        await getMethod(urls.getDriverAllDetail + "?order_number=" + orderNumber + "&pacakage_id=" + packageId).then((response) => {
            if (response?.data?.ResponseCode === 200 || response?.data?.ResponseCode === 201) {
                setDriverAllDetail(response?.data?.ResponseData)
                setRatingValue(response?.data?.ResponseData?.driver_order_rating)
            }
        })
    }

    const handleStartProcessing = () => {
        setConfirmationModal(true);
        getSteps()
    }

    const handleCloseStartProcessing = () => {
        setConfirmationModal(false);
        orderStartProcessing()
        getSteps()
    }

    const handleCompleteModal = () => {
        getDriverDetail()
        setCompleteModal(true)
        setDriverOtpValue("")
        getSteps()
    }

    const closeCompleteProcess = () => {
        setCompleteModal(false)
        getSteps()
    }

    const handleWithoutExpreesModal = () => {
        setExpressWithout(true)
        getSteps()
    }

    const closeWithoutExpreesModal = () => {
        setExpressWithout(false)
        getSteps()
    }

    const handleOrderNotes = (e) => {
        setOrderNoteMsg(e.target.value)
    }

    const handleOrderCancleNotes = (e, type) => {
        const { name, value } = e.target;

        if (type === 'cancel' || type === 'cancelProcess') {
            setCancleStateValue((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            setReturnStateValue((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }

    }

    const handleOrderCancleCheckbox = (e, id, index, type) => {
        if (type === 'cancel' || type === 'cancelProcess') {
            const list = [...cancleProductIds];
            if (e.target.checked) {
                if (!list.includes(id)) {
                    list.push(id)
                }
            } else {
                let indx = list.indexOf(id);
                list.splice(indx, 1);
            }
            setCancleProductIds(list)
            setCancleStateValue((prevState) => ({
                ...prevState,
                ['productId']: list,
            }));
        } else {
            const list = [...returnProductIds];

            if (e.target.checked) {
                if (!list.includes(id)) {
                    list.push(id)
                }
            } else {
                let indx = list.indexOf(id);
                list.splice(indx, 1);
            }

            setReturnProductIds(list)
            setReturnStateValue((prevState) => ({
                ...prevState,
                ['productId']: list,
            }));
        }
    }

    const handleSelectAll = (type) => {
        if (isSelectedAll !== true) {
            if (type === 'cancel' || type === 'cancelProcess') {
                const list = [...cancleProductIds];

                setIsSelectedAll(true)
                productInfoData.forEach((x, i) => {
                    if (!list.includes(x.product_id)) {
                        list.push(x.product_id)
                    }
                });

                setCancleProductIds(list)
                setCancleStateValue((prevState) => ({
                    ...prevState,
                    ['productId']: list,
                }));
            } else {
                const list = [...returnProductIds];

                setIsSelectedAll(true)
                productInfoData.forEach((x, i) => {
                    if (!list.includes(x.product_id)) {
                        list.push(x.product_id)
                    }
                });

                setReturnProductIds(list)
                setReturnStateValue((prevState) => ({
                    ...prevState,
                    ['productId']: list,
                }));
            }

        } else {
            if (type === 'cancel' || type === 'cancelProcess') {
                setCancleProductIds([])
                setCancleStateValue((prevState) => ({
                    ...prevState,
                    ['productId']: [],
                }));
                setIsSelectedAll(false)
            } else {
                setReturnProductIds([])
                setReturnStateValue((prevState) => ({
                    ...prevState,
                    ['productId']: [],
                }));
                setIsSelectedAll(false)
            }
        }
    }

    const handleAmountChange = (e) => {
        const { name, value } = e.target;
        if (value) {
            setRefundStateValue((prevState) => ({
                ...prevState,
                [name]: +((value) * 100)?.toFixed(),
            }));
        } else {
            setRefundStateValue((prevState) => ({
                ...prevState,
                [name]: 0,
            }));
        }
    };

    const onOrderSaveNotes = async () => {
        let body = {
            user: getUserRole() === "super-admin" ? OrderIdParam.store_id : getStoreId(),
            order_id: getUserRole() === "super-admin" ? OrderIdParam.order_id : OrderIdParam.id,
            message: orderNoteMsg,
        }


        await (OrderIdParam.order === "pickUp" ? postMethodWithPickUp : postMethodWithToken)(urls.postOrderNotes, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(toSentenceCase(response?.data?.response))
                getSteps()
                setIsNotesOpen(false)
                getOrderNotes()
            } else {
                toast.error(toSentenceCase(response?.data?.response))
            }
        });

    }

    const onDeleteNotes = async (noteId) => {
        await deleteMethodWithToken(urls.deleteOrderNote + noteId + '/').then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(toSentenceCase(response?.data?.response))
                getOrderNotes()
            } else {
                toast.error(toSentenceCase(response?.data?.response))
            }
        });
    }

    const onOpenNotesModal = () => {
        getSteps()
        setIsNotesOpen(true)
    }

    const onCloseNotesModal = () => {
        getSteps()
        setIsNotesOpen(false)
    }

    const onOpenCancleListModal = (value) => {
        setIsCancleListModalFlag(true)
        getSteps()

        if (value === 'cancel') {
            setProcessTypeModal('cancel')
        } else if (value === 'cancelProcess') {
            setProcessTypeModal('cancelProcess')
        } else {
            setProcessTypeModal('return')
        }
    }

    const onCloseCancleListModal = () => {
        setIsCancleListModalFlag(false)
        getSteps()
    }

    const onOpenRefundModal = async (value) => {
        setIsRefundModalFlag(true);
        getSteps()

        if (value === 'cancel') {
            setRefundTypeModal('cancel');
        } else {
            setRefundTypeModal('return');
        }
    }

    const onCloseRefundModal = () => {
        setIsRefundModalFlag(false);
        setRefundAmountShowOnlyItem(false);
        getSteps()
    }

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "28vw" : "25vw",
            height: screenSize.width <= 800 ? "15vh" : "18vh",
        },
    };

    const notesModalStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 1100 ? "35vw" : "25vw",
            height: "20vh",
            zIndex: "99",
        },
    };

    const cancleModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            transform: screenSize.width <= 800 ? 'translate(-32%, -55%)' : screenSize.width <= 1100 ? 'translate(-36%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "60vw",
            height: screenSize.width <= 800 ? "65vh" : screenSize.width <= 1100 ? "75vh" : "62vh",
            outline: "none",
            padding: "45px",
            zIndex: "99",
        },
    };
    const pickUpModalStyle = {
        content: {
            top: '45%',
            left: '50%',
            transform: screenSize.width <= 800 ? 'translate(-32%, -55%)' : screenSize.width <= 1100 ? 'translate(-36%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "55vw",
            height: screenSize.width <= 800 ? "40vh" : screenSize.width <= 1100 ? "75vh" : screenSize.width <= 1500 ? "43vh" : "38vh",
            outline: "none",
        },
    };

    const refundProcessModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            transform: screenSize.width <= 800 ? 'translate(-32%, -55%)' : screenSize.width <= 1100 ? 'translate(-36%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "50vw",
            height: screenSize.width <= 800 ? "45vh" : screenSize.width <= 1100 ? "45vh" : "40vh",
            outline: "none",
            padding: "15px",
            zIndex: "99",
        },
    };
    const deliverTypeModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            transform: screenSize.width <= 800 ? 'translate(-32%, -55%)' : screenSize.width <= 1100 ? 'translate(-36%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "48vw",
            height: screenSize.width <= 800 ? "52vh" : screenSize.width <= 1100 ? "55vh" : screenSize.width <= 1650 ? "51vh" : "42vh",
            outline: "none",
            padding: "15px 25px 0 45px",
            zIndex: "99",
        },
    }
    const driverOtpModalStyle = {
        content: {
            top: '50%',
            left: '50%',
            transform: screenSize.width <= 800 ? 'translate(-32%, -55%)' : screenSize.width <= 1100 ? 'translate(-36%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            width: "63vw",
            height: screenSize.width <= 800 ? "52vh" : screenSize.width <= 1100 ? "50vh" : "42vh",
            outline: "none",
            padding: "15px 30px 0 30px",
            zIndex: "99",
        },
    }
    const handleDeliverConfirmationModal = () => {
        setDeliverConfirmationModal(true)
        setIsCancleListModalFlag(false)
    }
    const handleCloseDeliverConfirmation = () => {
        setDeliverConfirmationModal(false)
    }
    const handleCancleReview = async (type) => {
        let isRedirectPage = false;

        if (type === 'cancelProcess' && cancleStateValue?.productId?.length > 0 && cancleStateValue?.message) {
            const error = checkCancleProcess(cancleStateValue);
            if (error) {
                toast.error(error);
            } else {
                await (OrderIdParam.order === "pickUp" ? postMethodWithPickUpPlatform : postMethodWithPlatform)(urls.postOrderCancellation, cancleStateValue, "merchant").then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (getUserRole() === "super-admin") {
                            getOrderDetails(urls.ordersAdmin + "?stores_id=" + OrderIdParam.store_id + "&order_id=" + OrderIdParam.order_id, "super-admin");
                        } else {
                            getOrderDetails(urls.ordersMerchant + "?order_id=" + OrderIdParam.id, "merchant");
                        }

                        toast.success(toSentenceCase(response?.data?.response));
                        setOrderStatus(response?.data?.status);
                        orderHistoryProcess();

                        isRedirectPage = true;
                    }
                }).catch((error) => {
                    console.log(error)
                });

                setIsCancleListModalFlag(false)

            }
        } else if (type !== 'cancelProcess') {
            const error = checkCancleProcess(cancleStateValue);

            if (error) {
                toast.error(error);
            } else {
                await (OrderIdParam.order === "pickUp" ? postMethodWithPickUpPlatform : postMethodWithPlatform)(urls.postOrderCancellation, cancleStateValue, "merchant").then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        if (getUserRole() === "super-admin") {
                            getOrderDetails(urls.ordersAdmin + "?stores_id=" + OrderIdParam.store_id + "&order_id=" + OrderIdParam.order_id, "super-admin");
                        } else {
                            getOrderDetails(urls.ordersMerchant + "?order_id=" + OrderIdParam.id, "merchant");
                        }

                        toast.success(toSentenceCase(response?.data?.response));
                        setOrderStatus(response?.data?.status);
                        orderHistoryProcess();
                        getSteps()

                        window.location.reload(false);
                    }
                }).catch((error) => {
                    console.log(error)
                });

                setIsCancleListModalFlag(false)
            }
        }

        if (type === 'cancelProcess') {
            if (cancleStateValue?.productId?.length > 0 || cancleStateValue.message !== "") {
                const error = checkCancleProcess(cancleStateValue);
                if (error) {
                    toast.error(error);
                } else {
                    if (cancleStateValue?.productId?.length === 0) {
                        toast.error("Please fill in product id");
                    } else {
                        orderFinishProcessing()
                        setIsCancleListModalFlag(false)

                        if (isRedirectPage === true) {
                            window.location.reload(false);
                        }
                    }

                }
            } else {
                orderFinishProcessing()
                setIsCancleListModalFlag(false)
                if (isRedirectPage === true) {
                    window.location.reload(false);
                }
            }
        }
    }

    const handleReturnReview = async () => {
        const error = checkReturnProcess(returnStateValue);

        if (error) {
            toast.error(error);
        } else {

            await (OrderIdParam.order === "pickUp" ? postMethodWithPickUpToken : postMethodWithToken)(urls.postOrderReturned, returnStateValue).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    if (getUserRole() === "super-admin") {
                        getOrderDetails(urls.ordersAdmin + "?stores_id=" + OrderIdParam.store_id + "&order_id=" + OrderIdParam.order_id, "super-admin");
                    } else {
                        getOrderDetails(urls.ordersMerchant + "?order_id=" + OrderIdParam.id, "merchant");
                    }

                    toast.success(toSentenceCase(response?.data?.response));
                    setOrderStatus(response?.data?.status);
                    orderHistoryProcess();

                    window.location.reload(false);
                }
            }).catch((error) => {
                console.log(error)
            });
        }
        setIsCancleListModalFlag(false)
    }

    const handleProcessRefund = async () => {
        const error = checkRefundProcess(refundStateValue, refundPrice);
        let body = {
            "productId": returnProductInfoData && returnProductInfoData.length > 0 ? returnRefundId : cancleProductInfoData && cancleProductInfoData.length > 0 ? cancelRefundId : '',
            "amount": refundStateValue.amount,
            "orderId": refundStateValue.orderId
        }
        if (error) {
            toast.error(error);
        } else {
            await postMethodWithToken(urls.postRefundProcess, body).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    toast.success(toSentenceCase(response?.data?.response));
                    setRefundStatus(response?.data?.refundStatus)
                    window.location.reload(false);
                } else {
                    toast.error(toSentenceCase(response?.data?.response));
                }
            }).catch((error) => {
                console.log(error)
            });
        }

        setIsRefundModalFlag(false)
    }
    const changeToFloat = (value) => {
        let valueInFloat = value !== null ? value.toFixed(2) : "";
        return valueInFloat;
    };
    const toggleVisible = () => {
        let heightToTop = 200;
        const scrolled = document.body.scrollTop || document.documentElement.scrollTop;
        if (scrolled > 0) {
            setVisible(false)
        }
        else if (scrolled <= 0) {
            setVisible(true)
        }
    };

    const scrollToBottom = () => {
        window.scrollTo({
            top: 960,
            behavior: 'smooth'
        });
    };
    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
    }, [])
    const scrollToBottomIcon = () => {
        window.scrollTo({
            top: 990,
            behavior: 'smooth'
        });
    };
    const iconBottom = () => {
        window.scrollTo({
            top: 840,
            behavior: 'smooth'
        });
    }

    const handleOtpChange = (e) => {
        setOtpValue(e)
    }
    const handleDriverOtpChange = (event) => {
        setDriverOtpValue(event)
    }

    const handlePickUp = async () => {
        let body = {
            otp: parseInt(otpValue)
        }
        await postMethodWithPickUp(urls.postPickUp + '?order_id=' + OrderIdParam.id, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(toSentenceCase(response?.data?.response))
                setPickUpModal(false)
                orderHistoryProcess();
                getSteps()
            } else {
                toast.error(toSentenceCase(response?.data?.response))
            }
        })
    }

    const closePickUpModal = () => {
        setPickUpModal(false);
        getSteps()
    }
    const handleAssignZapperChange = (event) => {
        setAssignZapper(event.target.checked)
        setCourierMethod(false)
    }

    const handleZapperIdChange = (event) => {
        setZapperIdValue(event?.target?.value)
    }

    const handleCourierChange = (event) => {
        setCourierMethod(event.target.checked)
        setAssignZapper(false)
    }
    const handleOptChange = (event) => {
        setOptMethod(event.target.checked)
    }
    const handleImageChange = (event) => {
        setImageMethod(event.target.checked)
    }
    const handleSignatureChange = (event) => {
        setSignatureMethod(event.target.checked)
    }

    const handleSubmitDeliverType = async () => {

        if (otpMethod === false && imageMethod === false && signatureMethod === false) {
            toast.error("Please select one of these filled")
        } else {
            let body = {
                "shopeez_express_delivery": courierMethod,
                "is_otp": otpMethod,
                "is_signature": signatureMethod,
                "is_image": imageMethod,
                "order_id": OrderIdParam.id,
                "brodecast": "All"
            }
            if (assignZapper) {
                body["brodecast"] = zapperIdValue.toString()
            }
            let apiUrl;
            if (assignZapper) {
                apiUrl = urls.deliverTypePost + "prodcast=" + zapperIdValue;
            } else {
                apiUrl = urls.deliverTypePost + "prodcast=All"
            }
            await putMethodWithToken(urls.deliverTypePost + "", body).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    toast.success(toSentenceCase(response?.data?.response))
                    handleCancleReview('cancelProcess')
                }
            })

        }
    }
    const handleDriverOtpVerify = async () => {
        let body = {
            "order_id": OrderIdParam?.id,
            "otp": driverOtpValue
        }
        await postMethodWithToken(urls.driverOtpVerify, body).then((response) => {
            if (response?.data?.ResponseCode === 200 || response?.data?.ResponseCode === 201) {
                toast.success(toSentenceCase(response?.data?.ResponseData?.response))
                orderCompleteProcessing()
                getDriverDetail()
            } else {
                toast.error(toSentenceCase(response?.data?.ResponseData?.response))
            }
        })
    }

    const ratingArray = [1, 2, 3, 4, 5]

    const handleRating = async (indexValue) => {
        setRatingValue(indexValue + 1)
        let body = {
            "driver": driverAllDetail?.driver_id,
            "rating": indexValue + 1,
            "order": OrderIdParam?.id
        }

        await postMethodWithToken(urls.ratingPost, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(toSentenceCase(response?.data?.response))
                getDriverDetail()
            } else {
                toast.error(toSentenceCase(response?.data?.response))
                getDriverDetail()
            }
        })

    }

    const handleAssignZapper = async () => {
        let body = {
            "driver": zapperIdValue
        }
        await postMethodWithToken(urls.assignZapperPost, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(toSentenceCase(response?.data?.response))
                handleSubmitDeliverType()
            } else {
                toast.error(toSentenceCase(response?.data?.response))
            }
        })
    }

    const handleResendDriver = async () => {
        let body = {
            "package_id": packageId,
            "store_id": +getStoreId()
        }
        await postMethodWithToken(urls.resendDriver, body).then((response) => {
            console.log(response, "1212")
            if (response?.status === 200 || response?.status === 201) {
                toast.success(response?.data?.response)
                getDriverDetail()
            }
            else {
                toast.error(response?.data?.response)
                getDriverDetail()
            }
        })
    }


    return (
        <React.Fragment>
            <div className={props.menu ? "screenLeftMargin w-100" : "screenLeftMarginResponse w-100"}>
                <div className="col-12 mr-3 d-flex mt-3">
                    <div onClick={() => gotoBack()} style={{ cursor: "pointer" }}>
                        <ArrowBackIcon /> <a style={{ color: "#4472C4" }} className="ml-2">Orders</a>
                    </div>
                </div>

                <div className="row mr-3 mt-2">
                    <div className="col-lg-12 col-xl-8">
                        <div className="row">
                            <div className="col-lg-11 col-xl-11 col-sm-11 col-md-11">
                                <div className="mt-3 orderTabs">
                                    <Tabs
                                        value={currTab}
                                        onChange={handleTabChange}
                                        textColor="inherit"
                                        variant="standard"
                                    >
                                        <Tab
                                            label="Product Information"
                                        />

                                        <Tab
                                            label="Packing Slip"
                                        />
                                        {orderStatus !== 'Order Received' &&
                                            <Tab
                                                label="Invoice"
                                            />
                                        }
                                        {orderStatus === 'Order Returned' &&
                                            <Tab
                                                label="Return Label"
                                            />
                                        }
                                    </Tabs>
                                </div>
                            </div>
                            <div className="col-lg-1 col-xl-1 col-sm-2 col-md-2 mt-3 printIcon">
                                <PrintIcon style={{ cursor: "pointer", width: "25px", height: "25px" }} onClick={currTab === 0 ? handleProductList : (currTab === 1 ? handlePrintSlip : (currTab === 2 ? handlePrintInvoice : handlePrintReturn))} />
                                <div>Print</div>
                            </div>
                        </div>
                        <div className="container-fluid  p-0 m-0">
                            <div style={{ display: "flex" }}>
                                <div className="productInformation">
                                    <SwipeableViews index={currTab} onChangeIndex={handleChangeIndex} enableMouseEvents={true}>
                                        <div className="w-100" ref={componentRefProductList}>
                                            {orderProductInfoData.results && (orderProductInfoData?.results?.data?.length > 0 || orderProductInfoData?.results?.cancellation?.length > 0 || orderProductInfoData?.results?.returned?.length > 0) &&
                                                <ProductInfo
                                                    ref={componentRefProductList}
                                                    orderId={getUserRole() === "super-admin" ? OrderIdParam.order_id : OrderIdParam.id}
                                                    storeId={getUserRole() === "super-admin" ? OrderIdParam.store_id : getStoreId()}
                                                    orderProductInfoData={orderProductInfoData}
                                                    customerDetails={customerDetails}
                                                    orderStatus={orderStatus}
                                                />
                                            }
                                        </div>

                                        <div className="w-100" ref={componentRefSlip}>
                                            <PackingSlip
                                                ref={componentRefSlip}
                                                orderId={getUserRole() === "super-admin" ? OrderIdParam.order_id : OrderIdParam.id}
                                                storeId={getUserRole() === "super-admin" ? OrderIdParam.store_id : getStoreId()}
                                                customerDetails={customerDetails}
                                                orderStatus={orderStatus}
                                            />
                                        </div>

                                        {orderStatus !== 'Order Received' &&
                                            <div className="w-100" ref={componentRefInvoice}>
                                                <Invoice
                                                    ref={componentRefInvoice}
                                                    orderId={getUserRole() === "super-admin" ? OrderIdParam.order_id : OrderIdParam.id}
                                                    storeId={getUserRole() === "super-admin" ? OrderIdParam.store_id : getStoreId()}
                                                    customerDetails={customerDetails}
                                                    orderStatus={orderStatus}
                                                />
                                            </div>
                                        }

                                        {orderStatus === 'Order Returned' &&
                                            <div className="w-100" ref={componentRefReturn}>
                                                <PackiReturnLabelSlip
                                                    ref={componentRefReturn}
                                                    orderId={getUserRole() === "super-admin" ? OrderIdParam.order_id : OrderIdParam.id}
                                                    storeId={getUserRole() === "super-admin" ? OrderIdParam.store_id : getStoreId()}
                                                    customerDetails={customerDetails}
                                                />
                                            </div>
                                        }
                                    </SwipeableViews>
                                </div>
                                <div className="downIcon" >
                                    <ArrowDownwardIcon onClick={scrollToBottom} style={{ width: "35px", height: "30px" }} />
                                </div>

                                <div className="arrowDown" >
                                    <ArrowDownwardIcon onClick={iconBottom} style={{ width: "35px", height: "30px" }} />
                                </div>
                                <OnHoldModal isOnHold={modalMe} />
                            </div>

                        </div>

                    </div>
                    <div className="col-lg-12 col-xl-4">
                        {getUserRole() !== "super-admin" ? (
                            <>
                                {(OrderIdParam.order === "deliver") ? (
                                    <>
                                        {refundStatus !== 'Complete Refunded' &&
                                            <p className="h5-lable-heading mobile-top-set" style={{ textAlign: "center" }}> {(orderStatus === "Order Received") ? "Ready to process the order ?" : (orderStatus === "Order Cancelled" && refundTypeData?.data?.[1].refund_status === "Refunded completed") ? "Ready to process or refund the order ?" : (orderStatus === "Order in Process") ? "Order Ready for Shipping ?" : (orderStatus === "Order Ready") ? "Order is Shipped ?" : (orderStatus === "Order Shipped" && productInfoData?.length > 0 && refundTypeData?.data?.[1].refund_status !== "non-completed") ? (OrderIdParam?.express === "expressDelivery") ? "" : "Ready to return the order?" : (orderStatus === "Order Shipped" && orderReturnedFlag !== "Complete Returned") || (orderStatus === 'Order Returned' && orderReturnedFlag === 'Partially Returned' && productInfoData?.length > 0 && refundTypeData?.data?.[1].refund_status !== "non-completed") ? "Ready to return the order?" : (orderStatus === 'Order Returned' && orderReturnedFlag === 'Partially Returned' && productInfoData?.length > 0 && refundTypeData?.data?.[1].refund_status === "non-completed") ? "Ready to return & refund the order?" : (orderCancellationFlag && orderCancellationFlag === 'Partially Cancellation' && orderReturnedFlag === null && refundStatus !== "Partial Refunded") ? "Ready to process or refund the order" : (orderStatus === "Order Returned" || orderReturnedFlag === "Complete Returned") && refundTypeData?.data?.[1].refund_status === "non-completed" ? "Ready to refund the order ?" : (orderStatus === "Order Cancelled") && refundTypeData?.data?.[1].refund_status === "non-completed" ? "Ready to refund the order ?" : (orderCancellationFlag && orderCancellationFlag === "Partially Cancellation" && orderReturnedFlag === null) ? "Ready to process the order ?" : ""}</p>
                                        }

                                        <div className={orderStatus !== "Order Received" && orderStatus !== "Order in Process" && orderStatus !== "Order Ready" && orderStatus !== "Order Shipped" && orderStatus !== 'Order Cancelled' ? " pt-2" : "d-flex justify-content-center pt-2"}>
                                            {(orderStatus === 'Order Received' && orderStatus !== 'Order Cancelled') || (orderStatus === 'Order Cancelled' && orderOldStatus === 'Order Received' && orderCancellationFlag && orderCancellationFlag === 'Partially Cancellation') ?
                                                <Button className="btn btn-primary order-btn mr-3" onClick={() => handleStartProcessing()} name={buttonLabel}></Button>
                                                : (orderStatus === 'Order in Process' && orderStatus !== 'Order Cancelled') || (orderStatus === 'Order Cancelled' && orderOldStatus === 'Order in Process' && orderCancellationFlag && orderCancellationFlag === 'Partially Cancellation') ?
                                                    <Button className="btn btn-primary order-btn mr-3" onClick={() => onOpenCancleListModal('cancelProcess')} name={buttonLabel}></Button>
                                                    : (orderStatus === 'Order Ready' && orderStatus !== 'Order Cancelled') || (orderStatus === 'Order Cancelled' && orderOldStatus === 'Order Ready' && orderCancellationFlag && orderCancellationFlag === 'Partially Cancellation') ?
                                                        <Button className="btn btn-primary order-btn mr-3" onClick={() => ((OrderIdParam.order === "deliver" && OrderIdParam?.express) ? handleCompleteModal() : handleWithoutExpreesModal())} name={buttonLabel}></Button>
                                                        : orderStatus !== 'Order Returned' && orderStatus !== 'Order Cancelled' ?
                                                            <div className="d-flex justify-space-between">
                                                                {(OrderIdParam?.express === "expressDelivery") ?
                                                                    ""
                                                                    :
                                                                    (orderReturnedFlag !== "Complete Returned" && orderStatus !== 'Order Delivered') &&
                                                                    <Button className="btn btn-primary btn-lg mr-3" onClick={() => onOpenCancleListModal('return')} name={buttonLabel}></Button>
                                                                }
                                                                {refundTypeData?.data?.[1].refund_status === "non-completed" && refundStatus !== 'Complete Refunded' &&
                                                                    <Button className="btn btn-primary btn-lg mr-3 d-flex justify-content-center" onClick={() => (onOpenRefundModal('return'), setRefundAmountShowOnlyItem(true))} name="Process Refund"></Button>
                                                                }
                                                            </div>

                                                            : refundStatus !== 'Complete Refunded' && refundTypeData?.data?.[1].refund_status === "non-completed" ?
                                                                <div className="d-flex justify-content-center">
                                                                    {(orderStatus === 'Order Returned' && orderReturnedFlag === 'Partially Returned' && productInfoData?.length > 0) &&
                                                                        <Button className="btn btn-primary btn-lg mr-3" onClick={() => onOpenCancleListModal('return')} name={"Return Order"}></Button>
                                                                    }
                                                                    <Button className="btn btn-primary btn-lg mr-3 d-flex justify-content-center" onClick={() => onOpenRefundModal('return')} name="Process Refund"></Button>
                                                                </div>
                                                                :
                                                                (orderStatus === 'Order Returned' && orderReturnedFlag === 'Partially Returned' && productInfoData?.length > 0) ?
                                                                    <div className="d-flex justify-content-center">
                                                                        <Button className="btn btn-primary btn-lg mr-3" onClick={() => onOpenCancleListModal('return')} name={"Return Order"}>
                                                                        </Button>
                                                                    </div>

                                                                    :
                                                                    null

                                            }

                                            {orderStatus === 'Order Cancelled' && refundStatus !== 'Complete Refunded' && refundTypeData?.data?.[1].refund_status === "non-completed" ?
                                                <div className="d-flex justify-content-center">
                                                    <Button className="btn btn-cancle order-btn" onClick={() => onOpenRefundModal('cancel')} name="Process Refund"></Button>
                                                </div>
                                                : orderStatus === 'Order Received' || orderStatus === 'Order in Process' || orderStatus === 'Order Ready' ?
                                                    <Button className="btn btn-cancle order-btn" onClick={() => onOpenCancleListModal('cancel')} name="Cancel Order"></Button>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </>
                                )
                                    :
                                    (
                                        <>
                                            {refundStatus !== 'Complete Refunded' &&
                                                <p className="h5-lable-heading mobile-top-set" style={{ textAlign: "center" }}> {(orderStatus === "Order Received") ? "Ready to process the order ?" : (orderStatus === "Order Cancelled" && refundTypeData?.data?.[1].refund_status === "Refunded completed") ? "Ready to process or refund the order ?" : (orderStatus === "Order in Process") ? "Order Ready for Customer Pickup ?" : (orderStatus === "Order Ready" || orderStatus === "Order Shipped") ? "Customer Arrived for Pickup ?" : (orderStatus === "Order Picked Up" && productInfoData?.length > 0 && refundTypeData?.data?.[1].refund_status !== "non-completed") ? "Ready to return the order?" : (orderStatus === 'Order Returned' && orderReturnedFlag === 'Partially Returned' && productInfoData?.length > 0 && refundTypeData?.data?.[1].refund_status !== "non-completed") ? "Ready to return or refund the order?" : (orderStatus === 'Order Returned' && orderReturnedFlag === 'Partially Returned' && productInfoData?.length > 0 && refundTypeData?.data?.[1].refund_status === "non-completed") ? "Ready to return & refund the order?" : (orderCancellationFlag && orderCancellationFlag === 'Partially Cancellation' && orderReturnedFlag === null && refundStatus !== "Partial Refunded") ? "Ready to process or refund the order" : (orderStatus === "Order Returned" || orderReturnedFlag === "Complete Returned") && refundTypeData?.data?.[1].refund_status === "non-completed" ? "Ready to refund the order ?" : (orderStatus === "Order Cancelled") && refundTypeData?.data?.[1].refund_status === "non-completed" ? "Ready to refund the order ?" : (orderCancellationFlag && orderCancellationFlag === "Partially Cancellation" && orderReturnedFlag === null) ? "Ready to process the order ?" : ""}</p>
                                            }
                                            <div className={orderStatus !== "Order Received" && orderStatus !== "Order in Process" && orderStatus !== "Order Ready" && orderStatus !== "Order Shipped" && orderStatus !== 'Order Cancelled' ? " pt-2" : "d-flex justify-content-center pt-2"}>
                                                {(orderStatus === 'Order Received' && orderStatus !== 'Order Cancelled') || (orderStatus === 'Order Cancelled' && orderOldStatus === 'Order Received' && orderCancellationFlag && orderCancellationFlag === 'Partially Cancellation') ?
                                                    <Button className="btn btn-primary order-btn mr-3" onClick={() => handleStartProcessing()} name={buttonLabel}></Button>
                                                    : (orderStatus === 'Order in Process' && orderStatus !== 'Order Cancelled') || (orderStatus === 'Order Cancelled' && orderOldStatus === 'Order in Process' && orderCancellationFlag && orderCancellationFlag === 'Partially Cancellation') ?
                                                        <Button className="btn btn-primary order-btn mr-3" onClick={() => onOpenCancleListModal('cancelProcess')} name={buttonLabel}></Button>
                                                        :
                                                        ((orderStatus === 'Order Ready' || orderStatus === "Order Shipped") && orderStatus !== 'Order Cancelled') || (orderStatus === 'Order Cancelled' && orderOldStatus === 'Order Ready' && orderCancellationFlag && orderCancellationFlag === 'Partially Cancellation') ?
                                                            <Button className="btn btn-primary order-btn mr-3" onClick={orderCompleteProcessing} name={buttonLabel}></Button>
                                                            :
                                                            (orderStatus === "Order Picked Up" && orderReturnedFlag === null) ?
                                                                <div className="d-flex justify-content-center">
                                                                    <Button className="btn btn-primary btn-lg mr-3" onClick={() => onOpenCancleListModal('return')} name={"Return Order"}>
                                                                    </Button>
                                                                </div>
                                                                :
                                                                orderStatus !== 'Order Returned' && orderStatus !== 'Order Cancelled' && orderReturnedFlag !== "Complete Returned" ?
                                                                    <div className="d-flex justify-space-between">
                                                                        {orderReturnedFlag !== null && <Button className="btn btn-primary btn-lg mr-3" onClick={() => onOpenCancleListModal('return')} name={buttonLabel}></Button>}
                                                                        {refundTypeData?.data?.[1].refund_status === "non-completed" && refundStatus !== 'Complete Refunded' &&
                                                                            <Button className="btn btn-primary btn-lg mr-3 d-flex justify-content-center" onClick={() => (onOpenRefundModal('return'), setRefundAmountShowOnlyItem(true))} name="Process Refund"></Button>
                                                                        }
                                                                    </div>
                                                                    : refundStatus !== 'Complete Refunded' && (orderOldStatus === 'Order Shipped' || orderStatus === "Order Returned") && refundTypeData?.data?.[1].refund_status === "non-completed" ?
                                                                        <div className="d-flex justify-content-center">
                                                                            {(orderStatus === 'Order Returned' && orderReturnedFlag === 'Partially Returned' && productInfoData?.length > 0) &&
                                                                                <Button className="btn btn-primary btn-lg mr-3" onClick={() => onOpenCancleListModal('return')} name={"Return Order"}></Button>
                                                                            }
                                                                            <Button className="btn btn-primary btn-lg mr-3 d-flex justify-content-center" onClick={() => onOpenRefundModal('return')} name="Process Refund"></Button>
                                                                        </div>
                                                                        :
                                                                        (orderStatus === 'Order Returned' && orderReturnedFlag === 'Partially Returned' && productInfoData?.length > 0) ?
                                                                            <div className="d-flex justify-content-center">
                                                                                <Button className="btn btn-primary btn-lg mr-3" onClick={() => onOpenCancleListModal('return')} name={"Return Order"}>
                                                                                </Button>
                                                                            </div>
                                                                            :
                                                                            null


                                                }
                                                {orderStatus === 'Order Cancelled' && refundStatus !== 'Complete Refunded' && refundTypeData?.data?.[1].refund_status === "non-completed" ?
                                                    <div className="d-flex justify-content-center">
                                                        <Button className="btn btn-cancle order-btn" onClick={() => onOpenRefundModal('cancel')} name="Process Refund"></Button>
                                                    </div>
                                                    : orderStatus === 'Order Received' || orderStatus === 'Order in Process' || orderStatus === 'Order Ready' || orderStatus === "Order Shipped" ?
                                                        <Button className="btn btn-cancle order-btn" onClick={() => onOpenCancleListModal('cancel')} name="Cancel Order"></Button>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </>
                                    )
                                }


                            </>
                        )
                            : (
                                <p>&nbsp;</p>
                            )}

                        {getUserRole() !== "super-admin" &&
                            <div className="section1 pt-2 pr-3 pl-3">
                                <div className="row mt-2 mb-2">
                                    <div className="col-12">
                                        <h5 className="h4-label">Fulfillment Timeline</h5>
                                    </div>
                                </div>
                                {orderHistoryList && orderHistoryList?.length > 0 ?
                                    <>
                                        <div className="row mb-2">
                                            <div className="col-4 d-flex" style={{ alignItems: "center" }}>
                                                <h5 className="h5-label-status" style={{ color: "5A5A5A" }}>Status</h5>
                                            </div>
                                            <div className="col-8">
                                                {(orderCancellationFlag && orderCancellationFlag === 'Partially Cancellation') ?
                                                    <a className="order-status" style={{ color: '#FF0000' }}>{orderStatus}</a>
                                                    :
                                                    (orderReturnedFlag && orderReturnedFlag === 'Partially Returned') ?
                                                        <a className="order-status" style={{ color: '#C00000' }}>{orderStatus}</a>
                                                        :
                                                        <a className="order-status" style={{ color: orderStatusColor[orderStatus.replace(" ", "").replace(" ", "")] }}>{orderStatus}</a>
                                                }
                                            </div>
                                        </div>



                                        <div className="row mb-2">
                                            <div className="col-12 history-tl-container">

                                                {orderHistoryList && orderHistoryList?.length === 3 &&
                                                    <>
                                                        <div className="row history-item">
                                                            <div className="col-3">&nbsp;</div>
                                                            <div className="col-6 history-border">
                                                                <div className="d-flex history-icon">
                                                                    <img width="25" height="25" src={OrderShippedIcon} />
                                                                </div>
                                                                <div className="history-step d-flex"></div>
                                                            </div>
                                                            <div className="col-3">&nbsp;</div>
                                                        </div>
                                                    </>
                                                }

                                                {orderHistoryList && orderHistoryList?.length === 2 &&
                                                    <>
                                                        <div className="row history-item">
                                                            <div className="col-3">&nbsp;</div>
                                                            <div className="col-6 history-border">
                                                                <div className="d-flex history-icon">
                                                                    <img width="25" height="25" src={OrderShippedIcon} />
                                                                </div>
                                                                <div className="history-step d-flex"></div>
                                                            </div>
                                                            <div className="col-3">&nbsp;</div>
                                                        </div>
                                                        <div className="row history-item">
                                                            <div className="col-3">&nbsp;</div>
                                                            <div className="col-6 history-border">
                                                                <div className="d-flex history-icon">
                                                                    <img width="25" height="25" src={OrderReadyIcon} />
                                                                </div>
                                                                <div className="history-step d-flex"></div>
                                                            </div>
                                                            <div className="col-3">&nbsp;</div>
                                                        </div>
                                                    </>
                                                }

                                                {orderHistoryList && orderHistoryList?.length === 1 &&
                                                    <>
                                                        <div className="row history-item">
                                                            <div className="col-3">&nbsp;</div>
                                                            <div className="col-6 history-border">
                                                                <div className="d-flex history-icon">
                                                                    <img width="25" height="25" src={OrderShippedIcon} />
                                                                </div>
                                                                <div className="history-step d-flex"></div>
                                                            </div>
                                                            <div className="col-3">&nbsp;</div>
                                                        </div>
                                                        <div className="row history-item">
                                                            <div className="col-3">&nbsp;</div>
                                                            <div className="col-6 history-border">
                                                                <div className="d-flex history-icon">
                                                                    <img width="25" height="25" src={OrderReadyIcon} />
                                                                </div>
                                                                <div className="history-step d-flex"></div>
                                                            </div>
                                                            <div className="col-3">&nbsp;</div>
                                                        </div>
                                                        <div className="row history-item">
                                                            <div className="col-3">&nbsp;</div>
                                                            <div className="col-6 history-border">
                                                                <div className="d-flex history-icon">
                                                                    <img width="25" height="25" src={OrderinProcessIcon} />
                                                                </div>
                                                                <div className="history-step d-flex"></div>
                                                            </div>
                                                            <div className="col-3">&nbsp;</div>
                                                        </div>
                                                    </>
                                                }

                                                {orderHistoryList && orderHistoryList?.length > 0 && orderHistoryList.map((item, index) => {
                                                    return (
                                                        <>
                                                            {index <= 3 &&
                                                                <div className="row history-item">
                                                                    <div className="col-3 orderhistoryDate" style={{ fontSize: "12px" }}>
                                                                        {moment(item.date_created).format('YYYY/MM/DD HH:mm ')}
                                                                    </div>
                                                                    <div className={item.new_status.replace(" ", "").replace(" ", "") + "History col-6 history-border " + (orderCancellationFlag && orderCancellationFlag.replace(" ", "")) + " " + (orderReturnedFlag && orderReturnedFlag.replace(" ", ""))}>
                                                                        <div className="d-flex history-icon">
                                                                            {item.new_status === 'Order Received' ?
                                                                                <img width="25" height="25" src={OrderReceivedIcon} />
                                                                                : item.new_status === 'Order in Process' ?
                                                                                    <img width="25" height="25" src={OrderinProcessIcon} />
                                                                                    : item.new_status === 'Order Ready' ?
                                                                                        <img width="25" height="25" src={OrderReadyIcon} />
                                                                                        :
                                                                                        <img width="25" height="25" src={OrderShippedIcon} />
                                                                            }
                                                                        </div>
                                                                        <div className="history-step d-flex"></div>
                                                                    </div>
                                                                    <div className="col-3" style={{ fontSize: "12px" }}>
                                                                        {item?.new_status}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="row">
                                        <div className="col-12 historyLoader">
                                            <SpinnerLoader />
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                        <div className="section2 pt-2 pb-2 pr-3 pl-3">
                            <div className="row mt-2 mb-2">
                                <div className="col-12">
                                    <h5 className="h5-label">Customer Information</h5>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-12">
                                    <div className="h6-label-heading">
                                        {customerDetails && customerDetails?.first_name + " " + customerDetails?.last_name}
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <div style={{ display: "flex" }}>
                                        <EmailIcon fontSize="small" style={{ color: "#808080" }} />

                                        <div className="SlipCusEmail pl-2">
                                            {customerDetails && customerDetails?.email}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <div style={{ display: "flex" }}>
                                        {customerDetails && customerDetails?.mobile_number !== "" && customerDetails?.mobile_number !== null &&
                                            <PhoneIcon fontSize="small" style={{ color: "#808080" }} />
                                        }
                                        <div className="SlipCusCall pl-2">
                                            {customerDetails && customerDetails?.mobile_number}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <div style={{ display: "flex" }}>
                                        <PinDropIcon fontSize="small" style={{ color: "#808080" }} />
                                        <div className="SlipCusLocation pl-2">
                                            {customerDetails?.shipping_address?.line1} <br />
                                            {customerDetails?.shipping_address?.line4 + ", " + customerDetails?.shipping_address?.state + ", " + customerDetails?.shipping_address?.postcode}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {getUserRole() !== "super-admin" && (driverResponse === 200 || driverResponse === 201) &&
                            <>
                                <div className="section2 pt-2 pb-3">
                                    <div className="row mt-2 mb-2 pr-3 pl-4">
                                        <div className="col-12">
                                            <h5 className="h4-label">Zapper Details</h5>
                                        </div>
                                        <div className="col-12">
                                            <div className="d-flex m-2 pl-2 pt-1">
                                                <img src={driverAllDetail?.driver_image} alt="pickUpModalImage" style={{ width: "70px", height: "70px", borderRadius: "50%", border: "1px solid black" }} />
                                                <div>
                                                    <div className="h5-label" style={{ marginLeft: "20px" }}>{driverList?.["Full Name"]}</div>
                                                    <div style={{ color: "#496CE8", marginLeft: "23px" }}>{driverList?.["Contact Number"]}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 h5-label pt-2">Pick-up time: <span className="h6-label-heading">{driverAllDetail?.Pickup_datetime ? moment(driverAllDetail?.Pickup_datetime).format("hh:mm A , MMM DD YYYY") : "-"}</span></div>
                                        <div className="col-12 h5-label pt-1">Drop-off time: <span className="h6-label-heading">{driverAllDetail?.["drop-off_datetime"] ? moment(driverAllDetail?.["drop-off_datetime"]).format("hh:mm A , MMM DD YYYY") : "-"}</span></div>
                                        <div className="col-6 pt-3">
                                            <div className="h5-label">Pick-up Address</div>
                                            <div className="h6-label-heading">{driverAllDetail?.pickup_address}</div>
                                        </div>
                                        <div className="col-6 pt-3">
                                            <div className="h5-label">Drop-up Address</div>
                                            <div className="h6-label-heading">{driverAllDetail?.drop_address}</div>
                                        </div>

                                    </div>

                                </div>

                                {orderStatus === 'Order Delivered' &&
                                    <div className="pb-3" style={{ background: "#DCEAF7", paddingLeft: "13px" }}>
                                        <div className="col-12 pt-3">
                                            <h5 className="h4-label">Rate Your Zapper</h5>
                                        </div>
                                        <div className="d-flex pt-2 pl-3">
                                            {ratingArray && ratingArray.map((item, index) => (
                                                <div class={(index + 1 <= ratingValue) ? "star-rating" : "star-rating-empty"}>
                                                    <i class="fas fa-star" onClick={() => handleRating(index)} style={{ cursor: "pointer" }}></i>
                                                </div>

                                            ))}
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        {orderStatus === "Order Delivered" &&
                            <div className="section2 pt-2 pb-4 pr-3 pl-4">
                                <div className="row mt-2 mb-2">
                                    <div className="col-12">
                                        <h5 className="h4-label">Delivery Confirmation</h5>
                                    </div>
                                    {driverAllDetail?.delivery_image_data?.[0]?.esign !== null &&
                                        <>
                                            <div className="col-12 h5-label pt-2">Signature</div>
                                            <div className="deliver-box m-3">
                                                <a href={driverAllDetail?.delivery_image_data?.[0]?.esign} target="_blank">
                                                    <img src={driverAllDetail?.delivery_image_data?.[0]?.esign} style={{ width: "100px", height: "97px", padding: "10px" }} alt="" />
                                                </a>
                                            </div>
                                        </>
                                    }
                                    {driverAllDetail?.delivery_image_data?.[0]?.photo !== null &&
                                        <>
                                            <div className="col-12 h5-label">Drop-off Image</div>
                                            <div className="deliver-box m-3">
                                                <a href={driverAllDetail?.delivery_image_data?.[0]?.photo} target="_blank">
                                                    <img src={driverAllDetail?.delivery_image_data?.[0]?.photo} style={{ width: "100px", height: "97px", padding: "10px" }} alt="" />
                                                </a>
                                            </div>
                                        </>
                                    }
                                    {driverAllDetail?.is_otp_verified === true &&
                                        <div className="d-flex mt-3 ml-3">
                                            <div className="verify-otp-box"></div>
                                            <div className="h5-label ml-2">Verified OTP</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {getUserRole() !== "super-admin" &&
                            <>
                                <div className="section3 pt-2 pb-2 pr-3 pl-3">
                                    <div>
                                        <div className="row d-flex mt-2 mb-2">
                                            <div className="col-6">
                                                <h5 className="h5-label">Notes</h5>
                                            </div>
                                            <div className="col-6 d-flex justify-content-end">
                                                <a className="btn btn-cancle btn-sm" onClick={onOpenNotesModal}>Add Note</a>
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col-9 f-600">
                                                Note
                                            </div>
                                            <div className="col-3 f-600">
                                                Action
                                            </div>
                                        </div>

                                        {orderNotesList?.length > 0 ? orderNotesList.map((item, index) => {
                                            return (
                                                <div className="row mb-2 pt-2 pb-2" style={{ background: "#ECECEC" }}>
                                                    <div className="col-9">
                                                        {item?.message}
                                                        <div style={{ fontSize: "14px" }}>
                                                            {moment(item?.date_created).format("MMM DD, YYYY HH:mm ")}
                                                        </div>
                                                    </div>
                                                    <div className="col-3 d-flex" style={{ alignItems: "center" }}>
                                                        <DeleteIcon fontSize="small" style={{ cursor: "pointer", color: "#eb2813" }} onClick={() => onDeleteNotes(item?.id)} />
                                                    </div>
                                                </div>
                                            )
                                        }) : (
                                            <div className="row mb-2 pt-2 pb-2" style={{ background: "#ECECEC", textAlign: "center" }}>
                                                <div className="col-12">
                                                    No Notes Found!
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>

                            </>
                        }


                    </div>
                </div>
            </div>

            <Modal isOpen={pickUpModal} style={pickUpModalStyle} overlayClassName="myoverlay" closeTimeoutMS={500} >
                <div className="row mt-3">
                    <OnHoldModal isOnHold={modalMe} />
                    <div className="col-5 d-flex justify-content-center" style={{ alignItems: "center" }}>
                        <img src={pickUpModalImage} alt="PickUpModalImage" style={{ width: "200px", height: "100px" }} />
                    </div>
                    <div className="col-7 pt-3">
                        <div className="h4-label-heading">
                            Enter Your 6-digit pin
                        </div>
                        <ValidatorForm>
                            <div className="row">
                                <div className="col-6 otp-value">
                                    <OtpInput
                                        value={otpValue}
                                        onChange={(e) => handleOtpChange(e)}
                                        numInputs={6}
                                        className="pickup-otp"
                                        style={{ width: "1" }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="h5-label-pickup mt-4">
                                        I acknowledge the receipt of <span style={{ fontWeight: 600 }}>Order {customerDetails?.number} </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="h5-label-pickup-name mt-2">
                                        {customerDetails?.first_name} {customerDetails?.last_name}
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-5">
                                <div className="col-12 mt-4 d-flex">
                                    <div className="d-flex">
                                        <Button type="onSubmit" onClick={() => handlePickUp()} name="Ok" className="btn btn-primary-pickup mr-3" />
                                    </div>
                                    <div className="d-flex">
                                        <Button type="onSubmit" onClick={() => closePickUpModal()} name="Cancel" className="btn btn-cancle-pickup" />
                                    </div>
                                </div>
                            </div>
                        </ValidatorForm>
                    </div>
                </div>
            </Modal>


            <Modal isOpen={isNotesOpen} style={notesModalStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <OnHoldModal isOnHold={modalMe} />
                <div className="row">
                    <div className="col-12">
                        <TextareaAutosize onChange={(e) => handleOrderNotes(e)} className="input-textarea w-100" aria-label="minimum height" minRows={4} placeholder="Notes Descripation" />
                    </div>
                </div>
                <div className="row pb-5">
                    <div className="col-12 mt-3 d-flex justify-content-end">
                        <div className="d-flex">
                            <Button type="onSubmit" onClick={() => onOrderSaveNotes()} name="Save" className="btn btn-primary btn-sm mr-3" />
                        </div>
                        <div className="d-flex">
                            <Button type="onSubmit" onClick={() => onCloseNotesModal()} name="Cancel" className="btn btn-cancle btn-sm" />
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={isCancleListModalFlag} style={cancleModalStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <OnHoldModal isOnHold={modalMe} />
                <div className="row mb-3">
                    <div className="h4-label-heading col-12">
                        {processTypeModal === 'cancelProcess' ? <span style={{ marginLeft: "-5px" }}>Is this order partially fulfilled (if applicable)?</span> : processTypeModal === 'cancel' ? 'Select items to be cancelled' : 'Select items to be returned'}
                    </div>

                    {processTypeModal === 'cancelProcess' &&
                        <div className="f-500 col-12 mt-2">
                            <div style={{ color: "#404040", marginLeft: "-5px" }}>Select Items not fulfilled in this order</div>
                        </div>
                    }
                </div>

                <div className="row mb-3">
                    <div className="col-12">
                        <div className="row pb-2" style={{ borderBottom: "1px solid #CECECE" }}>
                            <div className="col-2 viewAll f-600" onClick={() => handleSelectAll(processTypeModal)}>{isSelectedAll === true ? "Unselect All" : "Select All"}</div>
                            <div className="col-4 f-600">Product</div>
                            <div className="col-2 f-600 d-flex justify-content-center">Price</div>
                            <div className="col-2 f-600 d-flex justify-content-center">Qty.</div>
                            <div className="col-2 f-600 d-flex justify-content-center">Total</div>
                        </div>

                        {productInfoData?.length > 0 && productInfoData.map((item, index) => {
                            return (
                                <div className="row mt-3 order_receive_box">
                                    <div className="col-1 d-flex" style={{ alignItems: "center" }}>
                                        <Checkbox
                                            color="primary"
                                            onChange={(e) => handleOrderCancleCheckbox(e, item?.product_id, index, processTypeModal)}
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                            checked={cancleProductIds.includes(item?.product_id) ? true : (returnProductIds.includes(item?.product_id) ? true : false)}
                                        />
                                    </div>
                                    <div className="col-5">
                                        <div className="left_img_screen" style={{ display: 'inline-block' }}><img width="75" style={{ maxWidth: 'fit-content', marginRight: "20px" }} height="75" src={item?.product_images?.[0]?.original ? item?.product_images?.[0]?.original : DefaultImage} /></div>
                                        {/* </div>
                                    <div className="col-3"> */}
                                        <div style={{ display: 'inline-block', verticalAlign: 'top', paddingLeft: '3px' }}> <div className="h6-label f-600">{item.product_name}</div>
                                            <div><b>sku:</b> {item?.sku}</div>
                                            <div><b>brand:</b> {item?.brand}</div></div>
                                    </div>
                                    <div className="col-2 d-flex justify-content-center">
                                        $ {(+ item?.price)?.toFixed(2)}
                                    </div>
                                    <div className="col-2 d-flex justify-content-center">
                                        {(+ item?.quantity)}
                                    </div>
                                    <div className="col-2 d-flex justify-content-center">
                                        $ {(+ item?.quantity * item?.price)?.toFixed(2)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-12 mb-1">
                        <div className="h5-lable-heading">
                            {processTypeModal === 'cancelProcess' ? 'Reason for partial fulfillment (If applicable)' : processTypeModal === 'cancle' ? 'Reason for cancel' : 'Reason for cancellation'}
                        </div>
                    </div>
                    <div className="col-12">
                        <TextareaAutosize onChange={(e) => handleOrderCancleNotes(e, processTypeModal)} name="message" className="input-textarea w-100" aria-label="minimum height" minRows={4} placeholder={processTypeModal === 'cancelProcess' ? "Enter Remarks" : "Reason for Cancellation"} style={{ paddingLeft: "10px" }}
                        />
                    </div>
                </div>

                <div className="row pb-5">
                    <div className="col-12 mt-3 d-flex justify-content-end">
                        <div className="d-flex">
                            {processTypeModal === 'cancel' ?
                                <Button type="onSubmit" onClick={() => handleCancleReview('cancel')} name="Process" className="btn btn-primary mr-3" />
                                : processTypeModal === 'cancelProcess' ?
                                    <Button type="onSubmit" onClick={() => ((OrderIdParam.order === "deliver" && OrderIdParam?.express) ? (cancleStateValue?.productId?.length > 0 || cancleStateValue.message !== "") ? handleCancleReview('cancelProcess') : handleDeliverConfirmationModal() : handleCancleReview('cancelProcess'))} name="Proceed" className="btn btn-primary mr-3" />
                                    :
                                    <Button type="onSubmit" onClick={() => handleReturnReview()} name="Process" className="btn btn-primary mr-3" />
                            }

                        </div>
                        <div className="d-flex">
                            <Button type="onSubmit" onClick={() => onCloseCancleListModal()} name="Cancel" className="btn btn-cancle" />
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={isRefundModalFlag} style={refundProcessModalStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <OnHoldModal isOnHold={modalMe} />
                <div className="row mt-3 mb-3">
                    <div className="h4-label col-12">
                        Amount to be refunded
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {refundTypeData && refundTypeData.data && refundTypeData?.data?.length > 0 && refundTypeData.data.map((item, index) => {
                            return (
                                <>
                                    <div className="row mt-4">

                                        <div className="col-9">
                                            {item?.lable === 'complete refund' ? 'Issue full refund' : ''
                                                // 'Issue partial refund'
                                            }

                                        </div>
                                        <div className="col-3">

                                            {item.lable === 'complete refund' &&
                                                <TextField
                                                    className="amount"
                                                    label="Amount"
                                                    name="amount"
                                                    variant="outlined"
                                                    value={refundPrice}
                                                    disabled={true}
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                        startAdornment: (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="totalrefundLabel h5-label" style={{ color: "#888888" }}>Refund Amount:</div>
                        <div className="totalRefundAmount h2-label">${(refundStateValue?.amount / 100)?.toFixed(2)}</div>
                    </div>
                </div>

                <div className="row pb-5">
                    <div className="col-12 mt-3 d-flex justify-content-end">
                        <div className="d-flex">
                            <Button type="onSubmit" onClick={() => handleProcessRefund()} name="Process Refund" className="btn btn-primary mr-3" />
                        </div>
                        <div className="d-flex">
                            <Button type="onSubmit" onClick={() => onCloseRefundModal()} name="Cancel" className="btn btn-cancle" />
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={confirmationModal} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="mt-4 mb-4">
                    <div className="row">
                        <OnHoldModal isOnHold={modalMe} />
                        <div className="col-12 text-center">
                            Do you want the print packing slip?
                        </div>
                        <div className="col-12 mt-4  d-flex justify-content-center">
                            <Button type="onSubmit" onClick={() => handleCloseStartProcessing()} name="No" className="btn btn-sm btn-cancle mr-3" />
                            <Button type="onSubmit" onClick={() => { handlePrintSlip(); orderStartProcessing() }} name="Yes" className="btn btn-sm btn-primary" />
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={completeModal} style={driverOtpModalStyle} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="mr-2 mb-4">
                    <div className="row">
                        <OnHoldModal isOnHold={modalMe} />
                        <div className="h4-label ml-3">Courier Confirmation​</div>
                        {(driverResponse === 200 || driverResponse === 201) ?
                            <Box style={{ paddingLeft: '15px', paddingRight: '15px', maxWidth: '168px', height: "23px", marginLeft: '15px', display: 'flex', alignItems: "center" }} bgcolor="#00B050" color="white" textAlign="center" borderRadius="20px">
                                Accepted
                            </Box>
                            :
                            <Box style={{ paddingLeft: '15px', paddingRight: '15px', maxWidth: '168px', height: "23px", marginLeft: '15px', display: 'flex', alignItems: "center" }} bgcolor="#FF0000" color="white" textAlign="center" borderRadius="20px">
                                Pending
                            </Box>


                        }
                        <div className="h6-label-deliver col-12 mt-2">Verify order details with the courier and enter the OTP they provide.​</div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 mt-3">
                            <div className="h5-label mt-1">Order No: <span className="h6-lable-about-store"> {driverList?.["Order Number"]}</span>​</div>
                            <div className="h5-label mt-1">Drop-off Location: <span className="h6-lable-about-store">{driverList?.["Drop-off Location"]}</span>​</div>
                            {console.log(driverList)}
                            <div className="h5-label mt-1">Estimated Drop-off Time: ​<span className="h6-lable-about-store">{(driverResponse === 200 || driverResponse === 201) ? ((driverList && +driverList?.["Estimated Time"] / 3600) * 100).toFixed(0) + " Mins" : "-"}</span>​</div>
                            <div className="mt-5 pt-2">
                                <div className="d-flex" >
                                    <div className="otp-heading" >Enter OTP:</div>
                                    <OtpInput
                                        value={driverOtpValue}
                                        onChange={(e) => handleDriverOtpChange(e)}
                                        numInputs={6}
                                        className="driver-otp"
                                        inputStyle={{
                                            width: '40px',
                                            height: '40px',
                                            margin: '0 8px',
                                            fontSize: '16px',
                                            border: '2px solid #203763',
                                        }}
                                    />
                                </div>
                            </div>
                            {(driverResponse === 200 || driverResponse === 201) ?
                                ""
                                :
                                <div className="row">
                                    <div className="heading-resend-order">
                                        <div className="h5-label-resend" style={{ cursor: "pointer", color: "#4472C4" }} onClick={() => handleResendDriver()}>
                                            Resend Order
                                        </div>
                                    </div>
                                </div>

                            }
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 mt-3" style={{ background: "#D7D7D7", padding: "15px 30px 15px 40px" }}>
                            <div className="h5-label">Courier Details</div>
                            <div className="d-flex m-2">
                                <img src={driverAllDetail?.driver_image ? driverAllDetail?.driver_image : pickUpModalImage} alt="pickUpModalImage" style={{ width: "60px", height: "60px", borderRadius: "50%", border: "1px solid black" }} />
                                <div>
                                    <div className="h5-label ml-3">{driverList?.["Full Name"]}</div>
                                    <div style={{ color: "#496CE8", marginLeft: "12px" }}>{driverList?.["Contact Number"]}</div>
                                    <div className="f-600 ml-3">Vehicle: <span style={{ fontWeight: 400 }}>{driverAllDetail?.vehicle_data?.vehicle_type_name}</span>​</div>
                                    <div className="f-600 ml-3">{driverAllDetail?.vehicle_data && "License Plate:"} <span style={{ fontWeight: 400 }}>{driverAllDetail?.vehicle_data && driverAllDetail?.vehicle_data?.vehicle_number}</span></div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-4 f-600 text-center">{driverList?.Ratings}</div>
                                <div className="col-4 f-600 text-center">{driverList?.Total_deliveries}</div>
                                <div className="col-4 f-600 text-center">{driverList?.Months}</div>
                                <div className="col-4 text-center">Rating</div>
                                <div className="col-4 text-center">Deliveries​</div>
                                <div className="col-4 text-center">Months</div>
                            </div>
                        </div>
                        <div className="col-7" />
                        <div className="col-5 d-flex justify-content-between mt-4">
                            <Button type="onSubmit" onClick={() => closeCompleteProcess()} name="Cancel" className="btn btn-md btn-cancle col-6 mr-3" />
                            <Button type="onSubmit" disabled={driverOtpValue === "" ? true : driverOtpValue && driverOtpValue?.length < 6} onClick={() => handleDriverOtpVerify()} name="Proceed" className="btn btn-md btn-primary col-6" />
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={deliverConfirmationModal} style={deliverTypeModalStyles} overlayClassName="myoverlay" closeTimeoutMS={500} >
                <div className="mt-2 mb-4">
                    <div className="row">
                        <OnHoldModal isOnHold={modalMe} />
                        <div className="h4-label-heading col-12">Select courier options</div>
                        <div className="f-500 col-12 mt-2" style={{ color: "#404040" }}>Pick one of the two available options for Rush Order​</div>
                        <div className="row mt-2" style={{ paddingLeft: "10px" }} >
                            <div className="col-1 mt-3" >
                                <Checkbox
                                    checked={assignZapper}
                                    onChange={(e) => handleAssignZapperChange(e)}
                                    color="primary"
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                            </div >
                            <div className="col-7 h6-label-deliver-check" style={{ marginLeft: "-9px", marginTop: "18px" }}>
                                Assign this order to Zapper ID:
                            </div>
                            <div className="col-3 zapper-input">
                                <input type="number" value={zapperIdValue} disabled={(assignZapper === true && zapperIdValue !== "") || (assignZapper === false && zapperIdValue !== "")} onChange={(e) => handleZapperIdChange(e)} />
                            </div>

                        </div>
                        <div className="col-12 mt-3" style={{ paddingLeft: "10px", paddingBottom: "15px" }}>
                            <Checkbox
                                checked={courierMethod}
                                onChange={(e) => handleCourierChange(e)}
                                color="primary"
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                            <span className="h6-label-deliver-check">Send a request to the nearby Zapper couriers.</span>
                        </div>

                        <hr style={{ width: "100%" }} />
                        <div className="h4-label-heading col-12">Select delivery confirmation options</div>
                        <div className="f-500 col-12 mt-2" style={{ color: "#404040" }}>Check one or more delivery confirmation options based on the order value​​</div>
                        <div className="row mt-2" style={{ paddingLeft: "10px" }}>
                            <div className="col-12 mt-2">
                                <Checkbox
                                    checked={otpMethod}
                                    onChange={(e) => handleOptChange(e)}
                                    color="primary"
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                    disabled={!assignZapper && !courierMethod}
                                />
                                <span className="h6-label-deliver-check">OTP Verified Delivery​</span>
                            </div>
                            <div className="col-12 mt-3">
                                <Checkbox
                                    checked={imageMethod}
                                    onChange={(e) => handleImageChange(e)}
                                    color="primary"
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                    disabled={!assignZapper && !courierMethod}
                                />
                                <span className="h6-label-deliver-check">Image Verified Drop-off​</span>
                            </div>
                            <div className="col-12 mt-3">
                                <Checkbox
                                    checked={signatureMethod}
                                    onChange={(e) => handleSignatureChange(e)}
                                    color="primary"
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                    disabled={!assignZapper && !courierMethod}
                                />
                                <span className="h6-label-deliver-check">Signature Verified Delivery​</span>
                            </div>

                        </div>

                        <div className="col-12 mt-4  d-flex justify-content-end">
                            <Button type="onSubmit" onClick={() => handleCloseDeliverConfirmation()} name="Cancel" className="btn btn-sm btn-cancle mr-3" />
                            <Button type="onSubmit" onClick={() => {
                                if (zapperIdValue === "") {
                                    handleAssignZapper();
                                } else {
                                    handleSubmitDeliverType()
                                }
                            }} name="Submit" className="btn btn-sm btn-primary" />
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={expreeWithout} style={customStyles} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="mt-4 mb-4">
                    <div className="row">
                        <OnHoldModal isOnHold={modalMe} />
                        <div className="col-12 text-center">
                            Do you want complete the order ?
                        </div>
                        <div className="col-12 mt-4  d-flex justify-content-center">
                            <Button type="onSubmit" onClick={() => closeWithoutExpreesModal()} name="No" className="btn btn-sm btn-cancle mr-3" />
                            <Button type="onSubmit" onClick={() => orderCompleteProcessing()} name="Yes" className="btn btn-sm btn-primary" />
                        </div>
                    </div>
                </div>
            </Modal>

        </React.Fragment>
    );
}
export default OrdersTab;
