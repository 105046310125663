import React, { useEffect, useState } from "react";
import "./packingSlip.scss";
import FaceBook from "../../../../assets/images/Facebook_F.svg";
import Twitter from "../../../../assets/images/Twitter_T.svg";
import Insta from "../../../../assets/images/Instagram_I.svg";
import { getMethodWithPickUpPlatfrom, getMethodWithPlatfrom } from "../../../../utils/services/api";
import { getUserRole } from "../../../../utils/common";
import urls from "../../../../utils/services/apiUrl.json";
import moment from "moment";
import Barcode from 'react-barcode';
import Box from "@material-ui/core/Box";
import DefaultImage from "../../../../assets/images/ShopezDefult.svg"
import { useParams } from "react-router-dom";

function PackingSlip({ orderId, storeId, customerDetails, orderStatus }) {
    const OrderIdParam = useParams();
    const [orderPackingSlip, setOrderPackingSlip] = useState({});
    const [loading, setLoading] = useState(false);

    const getOrderDetails = async (url, type) => {
        setLoading(true);
        await (OrderIdParam.order === "pickUp" ? getMethodWithPickUpPlatfrom : getMethodWithPlatfrom)(url, type).then((response) => {
            if (response.status === 200) {
                setOrderPackingSlip(response?.data);
                setLoading(false);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (getUserRole() !== "super-admin") {
            getOrderDetails(urls.ordersMerchant + "?orders_id_packing_slip=" + orderId, "merchant");
        } else {
            getOrderDetails(urls.ordersAdmin + "?stores_id=" + storeId + "&orders_id_packing_slip=" + orderId, "super-admin");
        }
    }, []);

    const getDateFormat = (date) => {
        if (date !== null && date !== "") {
            return moment(date).format("L");
        }
        return;
    };

    const getTotalItems = () => {
        let totalCount = 0;
        if (orderPackingSlip && orderPackingSlip?.ordered_product?.length > 0) {
            for (let i = 0; i < orderPackingSlip?.ordered_product?.length; i++) {
                totalCount += orderPackingSlip?.ordered_product?.[i]?.ordered_quantity;
            }
        }
        return totalCount;
    };
    const timeFormat = (date) => {
        let requiredFormat = moment(date).format("lll");
        return requiredFormat;
    };

    return (
        <div className="row" style={{ marginLeft: "32px" }}>
            <div className="col-12" style={{ overflowX: "hidden" }}>
                <div className="row p-0 mt-4">
                    {OrderIdParam.order === "pickUp" ?
                        <div className="h4-label" style={{ display: "flex", alignItems: "center", paddingLeft: "14px" }}>
                            Pick Up Order {customerDetails && customerDetails?.number}
                        </div>
                        :
                        <div className="h4-label" style={{ display: "flex", alignItems: "center", paddingLeft: "12px" }}>
                            Ship to Address Order {customerDetails && customerDetails?.number}
                        </div>
                    }
                    <Box style={{
                        paddingLeft: '15px',
                        paddingRight: '15px',
                        maxWidth: '158px',
                        height: "32px",
                        marginLeft: '20px',
                        display: 'flex',
                        alignItems: "center"
                    }}
                        bgcolor="#00B050" color="white" textAlign="center" borderRadius="20px">
                        {customerDetails?.paymentConfirmation}
                    </Box>
                    {(OrderIdParam?.express && (orderStatus === "Order Received" || orderStatus === "Order in Process" || orderStatus === "Order Ready")) &&
                        <Box style={{ paddingLeft: '15px', paddingRight: '15px', maxWidth: '148px', height: "32px", marginLeft: '15px', display: 'flex', alignItems: "center" }} bgcolor="#FF0000" color="white" textAlign="center" borderRadius="20px">
                            Rush Order
                        </Box>
                    }
                    {(OrderIdParam?.express && orderStatus === "Order Delivered") &&
                        <Box style={{ paddingLeft: '15px', paddingRight: '15px', maxWidth: '148px', height: "32px", marginLeft: '15px', display: 'flex', alignItems: "center" }} bgcolor="#1D3762" color="white" textAlign="center" borderRadius="20px">
                            Order Delivered
                        </Box>
                    }
                    <div className="col-md-12 col-lg-12">
                        <div className="shiptext2 mt-3">
                            Received on{" "}
                            {customerDetails && customerDetails?.date_placed ? timeFormat(customerDetails?.date_placed) : ""}
                            {" "}
                            from{" "}
                            {customerDetails && customerDetails?.first_name + " " + customerDetails?.last_name}
                        </div>
                    </div>
                </div>
                <div className="row mt-4 mr-4">
                    <div className="col-6">
                        <div className="h5-label">Packing Slip</div>
                        <div className="muiDescripationInfo">
                            <span><b>Order:</b> </span>
                            {orderPackingSlip && orderPackingSlip?.number}
                        </div>
                        <div className="muiDescripationInfo">
                            <span><b>Order Date:</b> </span>
                            {getDateFormat(orderPackingSlip && orderPackingSlip?.date_placed)}
                        </div>
                        <div className="muiDescripationInfo">
                            <span><b>Shipping:</b> </span>
                            {OrderIdParam.order === "pickUp" ? "In-Store Pickup" : "Express Shipping"}
                        </div>
                        <div className="muiDescripationInfo">
                            <span><b>Total Items:</b> </span>
                            {orderPackingSlip && orderPackingSlip.ordered_product !== undefined && orderPackingSlip?.ordered_product?.length > 0 ? getTotalItems() : ""}
                        </div>
                    </div>

                    <div className="col-6" style={{ textAlign: "right" }}>
                        <img className="listedImg100100" src={orderPackingSlip?.merchant_data?.store_logo?.[0]?.file ? orderPackingSlip?.merchant_data?.store_logo?.[0]?.file : DefaultImage} />
                        {/*<Barcode value={orderPackingSlip && orderPackingSlip.number} />*/}
                    </div>
                </div>

                <div className="row mt-4 mr-4">
                    <div className="col-6 billto">
                        <div className="h5-label">Bill to</div>
                        <div className="muiDescripationInfoCheckbox">
                            {orderPackingSlip?.billing_address?.line1} <br />
                            {/* {orderPackingSlip?.billing_address?.line2}
                            {orderPackingSlip?.billing_address?.line3} */}
                            {orderPackingSlip?.billing_address?.line4 + ", "}
                            {orderPackingSlip?.billing_address?.state + ", "}
                            {orderPackingSlip?.billing_address?.postcode}
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="h5-label">Ship to</div>
                        <div className="muiDescripationInfoCheckbox">
                            {orderPackingSlip?.shipping_address?.line1} <br />
                            {/* {orderPackingSlip?.shipping_address?.line2}
                            {orderPackingSlip?.shipping_address?.line3} */}
                            {orderPackingSlip?.shipping_address?.line4 + ", "}
                            {orderPackingSlip?.shipping_address?.state + ", "}
                            {orderPackingSlip?.shipping_address?.postcode}
                        </div>
                        <div className="muiDescripationInfoCheckbox">
                            {/* {orderPackingSlip && orderPackingSlip.shipping_address && orderPackingSlip.shipping_address.phone_number} */}
                            {customerDetails?.mobile_number}
                        </div>
                    </div>
                </div>
                <div className="row mt-3 mr-2">
                    <div className="col-12">
                        <hr style={{ border: "1px solid #C0C0C0", marginTop: "4vw" }} />
                    </div>
                </div>

                {orderPackingSlip?.ordered_product?.length > 0 &&
                    <>
                        <div className="row mr-2">

                            <div className={OrderIdParam.order === "pickUp" ? "col-4 f-600" : "col-9 f-600"}>Item Discription</div>
                            {OrderIdParam.order === "pickUp" &&
                                <div className="col-4 f-600">Pick Up Date And  Time</div>
                            }
                            <div className={OrderIdParam.order === "pickUp" ? "col-4 f-600" : "col-3 f-600"} style={{ alignItems: "center" }}>Qty.</div>
                        </div>

                        <div className="row mt-3 mr-2">
                            <div className="col-12">
                                <hr style={{ border: "1px solid #C0C0C0", marginTop: "4vw" }} />
                            </div>
                        </div>

                        {orderPackingSlip && orderPackingSlip.ordered_product !== undefined && orderPackingSlip?.ordered_product?.length > 0 ? orderPackingSlip.ordered_product.map((item, index) => {
                            return (
                                <div className="row mr-2 pb-3">
                                    <div className={OrderIdParam.order === "pickUp" ? "col-4 d-flex " : "col-9 d-flex "}>
                                        <div className="d-flex">
                                            <img className="listedImg" src={item?.product_image?.[0]?.original ? item?.product_image?.[0]?.original : DefaultImage} alt={item?.product_name} />
                                        </div>

                                        <div className="ml-2" style={{ alignItems: "center" }}>
                                            <div className="h6-label">{item?.product_name}</div>
                                            <div className="pDetail"><b style={{ textTransform: "capitalize" }}>sku</b> : {item?.partner_sku}</div>
                                            {item?.attributes?.length > 0 &&
                                                (
                                                    <>
                                                        {item.attributes.map((attribute, index) => {
                                                            return (
                                                                <>
                                                                    {attribute.value !== null && attribute.code !== "color_code_swatch" &&
                                                                        <div className="pDetail" style={{ textTransform: "capitalize" }}><b>{attribute?.name}</b> : {attribute?.value}</div>
                                                                    }
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                    {OrderIdParam.order === "pickUp" ?
                                        <div className="col-4 d-flex" style={{ alignItems: "center" }}><span className="pick-time">{item?.store_timing !== "asap" ? moment(item?.store_timing).format('lll') : "At the earliest"}</span></div>
                                        :
                                        // <div className="col-4 d-flex" style={{ alignItems: "center" }}></div>
                                        ""
                                    }
                                    <div className={OrderIdParam.order === "pickUp" ? "col-4 d-flex" : "col-3 d-flex"} style={{ alignItems: "center" }}>
                                        {item?.ordered_quantity}
                                    </div>
                                </div>
                            );
                        }) : ""}

                        <div className="row mr-2">
                            <div className="col-12">
                                <hr style={{ border: "1px solid #C0C0C0" }} />
                            </div>
                        </div>

                        {/*<div className="row mt-2 ml-2 mr-2">
                    <div className="col-12">
                        <p>
                            <span style={{ color: "orange"}}>Note:</span>{" "}
                            This is a gift.
                        </p>
                    </div>
                </div>*/}
                    </>
                }

                {/* <div className="row mt-5 ml-2 mr-2 mb-5">
                    <div className="col-12">
                        <div className="h5-label text-center">Thank for your business!</div>
                        <div className="muiDescripationInfo text-center">
                            Please complete the above form & return it with items carefully packed.
                        </div>
                        <div className="mt-3">
                            <div className="h5-label text-center">Address</div>
                            <div className="muiDescripationInfo text-center">
                                {orderPackingSlip?.merchant_data?.address} <br />
                                {orderPackingSlip?.merchant_data?.city + ", "}
                                {orderPackingSlip?.merchant_data?.state + ", "}
                                {orderPackingSlip?.merchant_data?.postal_code} <br />
                                {orderPackingSlip?.merchant_data?.country}
                            </div>
                            <div className="muiDescripationInfo text-center">
                                Tel {orderPackingSlip && orderPackingSlip?.merchant_data && orderPackingSlip?.merchant_data?.contact_number}
                            </div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <img style={{ marginRight: "1vw" }} src={FaceBook} />
                            <img style={{ marginRight: "1vw" }} src={Twitter} />
                            <img style={{ marginRight: "1vw" }} src={Insta} />
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <Barcode value={orderPackingSlip && orderPackingSlip?.number} />
                        </div>
                        <div className="muiDescripationInfo text-center">
                            Please complete the above form & return it with items carefully packed.
                        </div>
                    </div>
                </div> */}
                <div className="row mt-2 mb-5 ml-2 mr-2">
                    <div className="col-12" style={{ marginTop: orderPackingSlip?.ordered_product?.length > 0 ? "" : "30px" }}>
                        <div className="h5-label text-center">Thank for your business!</div>
                        {/* <div className="muiDescripationInfo text-center">
                            Please complete the above form & return it with items carefully packed.
                        </div> */}

                        <div className="mt-2">
                            <div className="h5-label text-center">
                                {orderPackingSlip && orderPackingSlip?.merchant_data && orderPackingSlip && orderPackingSlip?.merchant_data?.store_name}
                            </div>
                            <div className="muiDescripationInfo text-center">
                                {orderPackingSlip?.merchant_data?.address} <br />
                                {orderPackingSlip?.merchant_data?.city + ", "}
                                {orderPackingSlip?.merchant_data?.state + ", "}
                                {orderPackingSlip?.merchant_data?.postal_code} <br />
                                {orderPackingSlip?.merchant_data?.country}
                            </div>
                            <div className="muiDescripationInfo text-center">
                                Tel {orderPackingSlip && orderPackingSlip?.merchant_data && orderPackingSlip?.merchant_data?.contact_number}
                            </div>
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <img style={{ marginRight: "1vw" }} src={FaceBook} />
                            <img style={{ marginRight: "1vw" }} src={Twitter} />
                            <img style={{ marginRight: "1vw" }} src={Insta} />
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <Barcode value={orderPackingSlip && orderPackingSlip?.number} />
                        </div>
                        <div className="muiDescripationInfo text-center">
                            Please complete the above form & return it with items carefully packed.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PackingSlip;
